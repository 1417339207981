<template>
    <div>
        <LiefengContent :isBack="$route.query.wechat ? false : true" @backClick="backClick">
            <template v-slot:title>
                群成员管理
                <span class="eye" @click.stop="changeEye">
                    <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
                </span>
                <!-- <Button type="error" v-if="!$route.query.groupType" @click="$router.push('/groupindex?isLineHome=true')" >返回</Button>
      <Button type="error" v-if="$route.query.groupType" @click="$router.push(`/grouplist?&isback=true&isLineHome=true&groupType=${$route.query.groupType}`)" >返回</Button> -->
            </template>
            <template v-slot:toolsbarLeft>
                <Button
                    type="primary"
                    @click="
                        showNumSelect++
                        addGroupPerson()
                    "
                >
                    添加群成员
                </Button>
            </template>

            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search">
                    <FormItem>
                        <Input :maxlength="20" v-model.trim="searchData.name" enter-button placeholder="姓名" style="width: 150px; margin-right: 10px" @on-enter="getList" />
                    </FormItem>
                    <FormItem>
                        <Input :maxlength="20" v-model.trim="searchData.account" enter-button placeholder="手机号" style="width: 150px; margin-right: 5px" @on-enter="getList" />
                    </FormItem>
                    <!-- <FormItem>
                    <Select transfer style="width: 180px; margin-right: 5px" v-model="searchData.labelCode" placeholder="选择身份类型">
                        <Option :value="item.extendValue" v-for="(item, index) in identityType" :key="index">{{ item.dictValue }}</Option>
                    </Select>
                </FormItem> -->
                    <FormItem>
                        <Select transfer style="width: 120px" v-model="searchData.joinType" placeholder="加入方式">
                            <Option :value="item.value" v-for="(item, index) in joinList" :key="index">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                    <Button style="margin-right: 5px" type="primary" icon="ios-search" @click="search">查询</Button>

                    <Button style="margin-right: 5px" icon="ios-refresh" type="success" @click="rest">重置</Button>
                    <Button @click="excelModalData(true)" type="warning" icon="ios-cloud-upload-outline" style="margin-right: 5px">导入</Button>
                    <Button v-if="$route.query.source === 'combination'" @click="onMassNotice" style="margin-right: 5px">发公告</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable :talbeColumns="talbeColumns" :tableData="tableData" height="200" :loading="loading" :fixTable="true" :curPage="page" :pagesizeOpts="[20, 30, 50, 100]" :total="total" :page-size="pageSize" @hadlePageSize="getList"></LiefengTable>

                <!-- 查看聊天记录弹窗 -->
                <LiefengModal title="个人聊天记录" :fullscreen="true" :value="chartModal" @input="chartModallFn">
                    <template v-slot:contentarea v-if="chartData">
                        <Card dis-hover>
                            <Row slot="title">
                                <Col span="5" style="line-height: 32px">
                                    <span>{{ chartData.name }}</span>
                                </Col>
                                <Col span="5" style="line-height: 32px">
                                    <span>{{ chartData.sexLabel }}</span>
                                </Col>
                                <Col span="5" style="line-height: 32px">
                                    <span>{{ chartData.account }}</span>
                                </Col>
                            </Row>
                        </Card>

                        <Table stripe :columns="chartTalbeColumns" :data="chartModalData">
                            <template slot-scope="{ row }" slot="msg">
                                <div style="padding: 20px">
                                    <video :src="row.msg" v-if="row.msgType == '4'"></video>
                                    <audio :src="row.msg" v-else-if="row.msgType == '3'"></audio>
                                    <img :src="row.msg" alt v-else-if="row.msgType == '2'" style="width: 100px; max-height: 100px" />
                                    <div v-else>{{ row.msg }}</div>
                                </div>
                            </template>
                            <template slot-scope="{ row }" slot="status">
                                <Checkbox :label="row.id" v-model="row.status" @on-change="changeStatus(row)">公开</Checkbox>
                            </template>
                        </Table>
                        <Page
                            style="display: flex; justify-content: flex-end; margin-top: 14px"
                            show-total
                            @on-change="chartCurrentPageFn"
                            @on-page-size-change="chartPageSizeFn"
                            :total="chartTotal"
                            size="small"
                            show-sizer
                            :page-size.sync="chartPageSize"
                            :curret.sync="chartPageNum"
                            show-elevator
                        ></Page>
                    </template>
                </LiefengModal>
                <!-- 导入excel弹窗 -->
                <LiefengModal class="export-modal" title="导入" width="40%" :value="excelStatus" @input="excelModalData">
                    <template v-slot:contentarea>
                        <!-- <Form :label-width="100">
            <FormItem>
               <span slot="label" class="validate">请选择社区:</span>
               <Tooltip placement="right-start"  style="width:100%">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="nodeId"
                  filterable
                  @on-change="getTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip>
            </FormItem>
          </Form> -->
                        <Upload
                            ref="excelUpload"
                            type="drag"
                            :action="action"
                            name="file"
                            :show-upload-list="false"
                            :before-upload="beforeExcelUpload"
                            :on-success="uploadSuccess"
                            :on-error="errorUpload"
                            :data="uploadData"
                            :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
                            :on-format-error="uploadFormatError"
                            :headers="headers"
                        >
                            <div style="padding: 20px 0; text-align: center; width: 100%">
                                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                                <p>点击上传，或将文件拖拽到此处</p>
                            </div>
                        </Upload>
                        <div v-if="excelFile != null">当前选择的文件名称:{{ excelFile.name }}</div>
                        <!-- <div v-html="errorContent"></div> -->
                        <div style="text-align: center; margin: 20px 0">
                            <Button type="primary" style="margin-right: 20px">
                                <a href="./excelfiles/group.xlsx" download="沟通组导入模板.xlsx" style="color: #fff">模板下载</a>
                            </Button>
                            <Button type="info" @click="submitExcel">确认导入</Button>
                        </div>
                    </template>
                </LiefengModal>

                <!-- 新增群成员 -->
                <LiefengModal title="新增群成员" class="group" :value="addStatus" @input="changeAddStatus" :fullscreen="true">
                    <template v-slot:contentarea>
                        <Selecttable v-if="selectTrue" ref="selecttable" @selectList="selectList" :selectOrg="selectOrg" :groupId="urlParams.groupId" :showNum="showNumSelect" :userSelectList="userSelectList"></Selecttable>
                    </template>
                    <template v-slot:toolsbar>
                        <Button type="info" style="margin-right: 10px" @click="changeAddStatus(false)">取消</Button>
                        <Button type="primary" :loading="addLoading" @click="saveSetPeople">确定</Button>
                    </template>
                </LiefengModal>
            </template>
        </LiefengContent>

        <CompModal title="畅联通" ref="modal" width="400px" @on-close="iframeUrl = null">
            <iframe v-if="iframeUrl" :src="iframeUrl" width="100%" height="100%" frameborder="0" style="display: block;"></iframe>
        </CompModal>
    </div>
</template>

<script>
//@route("/groupcontacter")
import LiefengTable from "@/components/LiefengTable"
import LiefengContent from "@/components/LiefengContent3"
import LiefengModal from "@/components/LiefengModal"
import Selecttable from "./childrens/selecttable"
import proxy from "@/api/proxy"
import Route from "../communitymobilization/entity/Route"
import CompModal from "../residentdatabase/components/CompModal.vue"

export default {
    data() {
        return {
            hasSensitive: true,
            addStatus: false,
            isAdd: false,
            selectTrue: true,
            selectOrg: this.$route.query.orgCode,
            groupId: "",
            allSelectList: [],
            isGetist: false,
            showNumSelect: 0,
            userSelectList: [],
            headers: { openId: window.sessionStorage.getItem("openId"), Authorization: window.sessionStorage.getItem("accessToken") },
            loading: false,
            urlParams: {
                groupId: "",
                orgCode: "",
            },
            total: 0,
            talbeColumns: [
                {
                    title: "来源社区",
                    key: "orgName",
                    width: 150,
                    align: "center",
                },
                {
                    title: "房号",
                    key: "houseNum",
                    width: 100,
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "name",
                    width: 100,
                    align: "center",
                },
                {
                    title: "昵称",
                    key: "nickname",
                    width: 120,
                    align: "center",
                },

                {
                    title: "性别",
                    key: "sex",
                    width: 80,
                    align: "center",
                },
                {
                    title: "年龄",
                    key: "age",
                    width: 120,
                    align: "center",
                },
                {
                    title: "联系电话",
                    key: "account",
                    width: 130,
                    align: "center",
                },
                {
                    title: "身份类型",
                    key: "labelNames",
                    width: 230,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.labelNames.length == 0 ? "" : params.row.labelNames.toString())
                    },
                },

                {
                    title: "所在详细地址",
                    key: "currentAddr",
                    minWidth: 200,
                    align: "center",
                    // render: (h, params) => {
                    //   return h("div", [
                    //     h(
                    //       "span",
                    //       {
                    //         style: {
                    //           display: "inline-block",
                    //           width: "100%",
                    //           overflow: "hidden",
                    //           textOverflow: "ellipsis",
                    //           whiteSpace: "nowrap"
                    //         },
                    //         domProps: {
                    //           title:
                    //             params.row.roadName && params.row.detailAddr
                    //               ? params.row.roadName + params.row.detailAddr
                    //               : params.row.roadName || params.row.detailAddr || ""
                    //         },
                    //         on: {
                    //           click: e => {
                    //             e.stopPropagation();
                    //           }
                    //         }
                    //       },
                    //       params.row.roadName && params.row.detailAddr
                    //         ? params.row.roadName + params.row.detailAddr
                    //         : params.row.roadName || params.row.detailAddr || ""
                    //     )
                    //   ]);
                    // }
                },
                {
                    key: "joinValue",
                    width: 120,
                    align: "center",
                    renderHeader: (h, params) => {
                        return h("div", [this.problem != "" ? this.problem : ""])
                    },
                },
                {
                    title: "加入方式",
                    key: "joinType",
                    width: 120,
                    align: "center",
                },
                {
                    title: "激活时间",
                    key: "activateTime",
                    width: 170,
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    fixed: "right",
                    width: this.$route.query.source === "combination" ? 160 : 80,
                    render: (h, params) => {
                        const btns = []

                        if (this.$route.query.source === "combination") {
                            btns.push(
                                h(
                                    "Button",
                                    {
                                        props: {
                                            size: "small",
                                        },
                                        on: {
                                            click: () => {
                                                this.onOpenMessageDetail(params.row)
                                            },
                                        },
                                        style: {
                                            margin: "0 5px",
                                        },
                                    },
                                    "发消息"
                                )
                            )
                        }

                        return h("div", [
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                            },
                                        },
                                        "编辑"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.setManage(params.row)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                params.row.manager == 1 ? "设为管理员" : "取消管理员" // 0 是 1 否"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    nativeOn: {
                                                        click: () => {
                                                            this.remove(params.row)
                                                        },
                                                    },
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                },
                                                params.row.contact == "1" ? "移除群成员" : params.row.contact == null ? "移除群成员" : "设置群成员"
                                            ),
                                            // h(
                                            //   "DropdownItem",
                                            //   {
                                            //     nativeOn: {
                                            //       click: () => {
                                            //         this.myChart(params.row);
                                            //       }
                                            //     },
                                            //     style: {
                                            //       textAlign: "center"
                                            //     }
                                            //   },
                                            //   "查看个人聊天"
                                            // )
                                        ]
                                    ),
                                ]
                            ),
                            ...btns,
                        ])
                    },
                },
            ],
            tableData: [],
            searchData: {
                name: "",
                account: "",
                pageSize: 20,
                page: 1,
                joinType: "",
                labelCode: "",
            },
            custGlobalId: "",
            page: 1,
            pageSize: 20,
            //查看聊天记录
            chartModal: false,
            chartData: null,
            chartModalData: [],
            chartTotal: 0,
            chartPageNum: 1,
            chartPageSize: 10,
            chartTalbeColumns: [
                {
                    title: "时间",
                    key: "gmtCreate",
                    width: 180,
                    align: "center",
                },
                {
                    title: "内容",
                    slot: "msg",
                    align: "center",
                },
                {
                    title: "状态",
                    slot: "status",
                    width: 110,
                    align: "center",
                },
                {
                    title: "操作",
                    width: 110,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    on: {
                                        click: () => {
                                            // this.setManage(params.row);
                                        },
                                    },
                                },
                                "回复"
                            ),
                        ])
                    },
                },
            ],

            //excel导入弹窗
            excelStatus: false,
            //excel文件数据
            excelFile: null,
            //导入参数
            uploadData: {},
            errorContent: "",
            action: "",
            groupList: [
                {
                    value: "1",
                    label: "仅户籍在此",
                },
                {
                    value: "2",
                    label: "是业主但户籍在此",
                },
                {
                    value: "3",
                    label: "是业主且户籍在此",
                },
                {
                    value: "4",
                    label: "租赁人员",
                },
                {
                    value: "5",
                    label: "其他",
                },
            ],
            joinList: [
                {
                    value: "",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "系统导入",
                },
                {
                    value: "2",
                    label: "邀请加入",
                },
                {
                    value: "3",
                    label: "系统添加",
                },
                {
                    value: "4",
                    label: "网上家园认证",
                },
            ],
            problem: "",
            nodeList: [],
            //异步加载社区
            loadingCommunity: false,
            nodeId: "",
            allNodeList: [],
            //身份类型
            identityType: [],
            addLoading: false,

            iframeUrl: null,
        }
    },

    watch: {
        iframeUrl(v) {
            v && this.$refs.modal.display()
        },
    },

    mounted() {
        if (this.$route.query.data) {
            let Base64 = require("js-base64").Base64
            let temp = Base64.decode(this.$route.query.data)
            this.urlParams = JSON.parse(temp)
        } else {
            this.urlParams = {
                groupId: this.$route.query.groupId,
                orgCode: this.$route.query.orgCode,
            }
        }
        this.custGlobalId = this.GetUrlMenuCode() ? this.GetUrlMenuCode() : "C1803272004238a9dc64d978532"
        this.getList()
        // this.getSelect()
        if (this.$route.query.groupId) {
            this.getProblem(this.$route.query.groupId)
        } else {
            this.problem = ""
        }
    },
    created() {
        this.getDicType("USER_IMP_TYPE", "identityType")
        console.log(this.$route.query.isAdd)
        //  this.getList()
    },
    methods: {
        // 点击添加群成员按钮
        addGroupPerson() {
            // this.userSelectList = this.tableData
            // this.allSelectList = this.tableData
            // this.changeAddStatus(true)
            this.$core.openLayerFrame(
                {
                    type: 2,
                    title: "添加群成员",
                    content: `/page#/customergroup?groupId=${this.$route.query.groupId}&type=type`,
                    area: ["100%", "100%"],
                },
                () => {},
                () => {},
                () => {
                    this.getList()
                }
            )
        },
        backClick() {
            if (Route.isBack()) return Route.back()

            if (this.$route.query.groupType) {
                this.$router.push(`/grouplist?&isback=true&isLineHome=true&groupType=${this.$route.query.groupType}`)
            } else {
                this.$router.push("/groupindex?isLineHome=true")
            }
        },
        // 获取字典接口的公共方法
        getDicType(type, model) {
            this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: type,
            }).then(res => {
                this[model] = res.dataList
            })
        },
        // 获取problem数据
        getProblem(groupId) {
            this.$post("/sendmsg/pc/chat/groupCreate/queryConfigByGroupId", {
                groupId: groupId,
            }).then(res => {
                let data = res.dataList
                if (data.length != 0) {
                    data.map(item => {
                        if (item.paraCode == "CHECK_QUESTION")
                            if (item.paraValue != "1") {
                                this.problem = item.paraValue
                            } else {
                                this.talbeColumns.splice(9, 1)
                                console.log(this.talbeColumns)
                            }
                    })
                } else {
                    this.talbeColumns.splice(9, 1)
                }
            })
        },
        //修改excel导入弹窗状态
        excelModalData(status) {
            this.excelStatus = status
        },
        //确认导入
        submitExcel() {
            // if(!this.uploadData.orgCode || this.uploadData.orgCode == ''){
            //   this.$Notice.error({
            //     title: "导入失败",
            //     desc: "请选择社区",
            //     duration: 3
            //   });
            //   return;
            // }
            if (!this.excelFile) {
                this.$Notice.error({
                    title: "导入失败",
                    desc: "请选择需导入的文件",
                    duration: 3,
                })
                return
            }
            this.$Message.loading({
                content: "正在上传文件，请稍等...",
                duration: 0,
            })
            this.$refs.excelUpload.post(this.excelFile)
        },
        // 导入失败
        errorUpload() {
            this.$Message.destroy()
            this.$Notice.error({
                title: "导入失败",
                desc: "",
                duration: 3,
            })
        },
        //导入excel格式错误
        uploadFormatError(file) {
            this.$Message.destroy()
            this.$Notice.warning({
                title: "文件格式不正确",
                desc: "文件" + file.name + "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
            })
        },
        //excel文件上传成功后执行
        uploadSuccess(res) {
            this.$Message.destroy()
            if (res.code == 200 && res.dataList.length == 0) {
                this.$Message.success({
                    background: true,
                    content: "上传成功！",
                })
                this.excelStatus = false
                this.excelFile = null
                this.getList({
                    pageSize: this.pageSize,
                    page: this.pageNum,
                })
            } else if (res.code == 200 && res.dataList.length > 0) {
                this.errorContent = ""
                res.dataList.map((item, index) => {
                    this.errorContent += `<div style="color:red">第${item.number + 2}行：${item.errorCode}</div>`
                })
                this.$Notice.success({
                    title: "数据导入失败，请排查以下问题后重新导入",
                    desc: this.errorContent,
                    duration: 3,
                })
            } else {
                this.$Notice.error({
                    title: "导入失败",
                    desc: res.desc,
                    duration: 3,
                })
            }
        },
        //上传之前
        beforeExcelUpload(file) {
            this.action = proxy["/syaa"].target + "/upload/sy/user/chatUser/importUser"
            this.uploadData = {
                oemCode: parent.vue.oemInfo.oemCode,
                groupId: this.urlParams.groupId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                staffName: parent.vue.loginInfo.userinfo.realName,
                memberFromScope: this.urlParams.memberFromScope,
            }

            this.excelFile = file
            return false
        },
        //个人聊天记录弹窗状态改变
        chartModallFn(status) {
            this.chartModal = status
        },
        GetUrlMenuCode() {
            let url = window.location.href
            let parameter = url.substring(url.indexOf("?") + 1)
            parameter = parameter.split("&")
            let reg = /custGlobalId=/g
            let menuCode = ""
            for (let i = 0; i < parameter.length; i++) {
                reg.lastIndex = 0
                if (reg.test(parameter[i])) {
                    menuCode = parameter[i].replace("custGlobalId=", "")
                    break
                }
            }
            return menuCode
        },
        search() {
            this.searchData = {
                name: this.searchData.name,
                account: this.searchData.account,
                pageSize: 20,
                page: 1,
                joinType: this.searchData.joinType,
                labelCode: this.searchData.labelCode,
            }
            this.getList(this.searchData)
        },
        rest() {
            this.searchData = {
                name: "",
                account: "",
                page: 1,
                pageSize: 20,
                joinType: "",
                labelCode: "",
            }
            this.getList(this.searchData)
        },
        getList(data) {
            this.loading = true
            if (data && data.page) {
                //改变当前页
                this.searchData.page = data.page
                // this.searchData.page = 1;
                this.searchData.pageSize = data.pageSize
            }
            if (data && data.page && (data.name || data.account || data.joinType || data.labelCode)) {
                //搜索
                this.searchData = {
                    ...data,
                }
            }
            let params = {
                ...this.searchData,
                groupId: this.urlParams.groupId,
            }

            this.$get("/sendmsg/pc/chat/groupCreate/queryMemberPage", {
                account: this.searchData.account,
                groupId: this.urlParams.groupId,
                joinType: this.searchData.joinType,
                name: this.searchData.name,
                page: this.searchData.page,
                pageSize: this.searchData.pageSize,
                labelCode: this.searchData.labelCode,
                hasSensitive: this.hasSensitive,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            })
                .then(res => {
                    if (res.code === "200") {
                        res.dataList.map(item => {
                            item.joinType = item.joinType == "1" ? "系统导入" : item.joinType == "2" ? "邀请加入" : item.joinType == "3" ? "系统添加" : item.joinType == "4" ? "网上家园认证" : ""
                            item.userType = item.userType == "1" ? "仅户籍在此" : item.userType == "2" ? "是业主但户籍不在此" : item.userType == "3" ? "是业主且户籍在此" : item.userType == "4" ? "租赁人员" : item.userType == "5" ? "其他" : ""
                            item.activateTime = item.activateTime ? this.$core.formatDate(new Date(item.activateTime), "yyyy-MM-dd hh:mm:ss") : ""
                            item.sex = item.sex == "0" ? "未知" : item.sex == "1" ? "男" : item.sex == "2" ? "女" : ""
                        })
                        this.tableData = res.dataList.map(item => {
                            return {
                                ...item,
                                _disabled: true,
                            }
                        })
                        this.total = res.maxCount
                        this.page = res.currentPage
                        this.loading = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "数据获取失败",
                        })
                        this.loading = false
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "数据获取失败",
                    })
                    this.loading = false
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.getList()
        },
        setSelection(selection) {
            this.selection = selection
        },
        addUser() {
            this.$Message.error("暂时接口")
        },
        setManage(row) {
            // console.log(row);
            // return;
            if (row.manager === 1) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>是否设为管理员吗？</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        let params = {
                            groupId: this.urlParams.groupId,
                            manager: row.name,
                            managerId: row.custGlobalId,
                            oemCode: parent.vue.loginInfo.userinfo.oemCode,
                            operCustGlobalId: this.custGlobalId,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            staffName: parent.vue.loginInfo.userinfo.realName,
                        }
                        this.$post("/sendmsg/pc/chat/groupmanager/addManager", params)
                            .then(res => {
                                if (res.code === "200") {
                                    this.$Message.success("操作成功")
                                    this.getList()
                                }
                            })
                            .catch(err => console.log(err))
                    },
                })
            } else {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>是否取消管理员</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        let params = {
                            groupId: this.urlParams.groupId,
                            manager: row.name,
                            custGlobalId: row.custGlobalId,
                            operCustGlobalId: this.custGlobalId,
                        }
                        this.$post("/sendmsg/pc/chat/groupmanager/delManager", params)
                            .then(res => {
                                if (res.code === "200") {
                                    this.$Message.success("操作成功")
                                    this.getList()
                                } else {
                                    this.$Message.error("操作失败")
                                }
                            })
                            .catch(err => console.log(err))
                    },
                })
            }
        },
        remove(data) {
            let params = {}
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>是否确定移除</p>",
                cancelText: "取消",
                okText: "确认",
                onOk: () => {
                    // if (data) {
                    //     if (data.contact == 1) {
                    //         data.contact = 2
                    //     } else if (data.contact == 2) {
                    //         data.contact = 1
                    //     } else {
                    //         data.contact = ""
                    //     }
                    //     params = {
                    //         custGlobalId: data.custGlobalId,
                    //         groupId: data.groupId,
                    //         familyId: data.familyId,
                    //         userId: data.userId,
                    //         contact: data.contact,
                    //     }
                    // }

                    // this.$post("/sendmsg/pc/chat/groupCreate/removeChatMember", params)
                    //     .then(res => {
                    //         if (res.code === "200") {
                    //             this.$Message.success("操作成功")
                    //             this.getList()
                    //         } else {
                    //             this.$Message.success("操作失败")
                    //         }
                    //     })
                    //     .catch(err => console.log(err))
                    this.$Message.loading({
                        content: "正在移除",
                    })
                    this.$post(
                        "/sendmsg/pc/chat/openUser/removeUser",
                        {
                            custGlobalId: data.custGlobalId,
                            groupIdList: [this.$route.query.groupId],
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        this.$Message.destroy()
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "操作成功",
                                background: true,
                            })
                            this.getList()
                            return
                        } else {
                            this.$Message.error({
                                content: "操作失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        //查看个人聊天
        myChart(row) {
            this.chartData = {
                name: row.name,
                houseNum: row.houseNum,
                sexLabel: row.sexLabel,
                account: row.account,
                chatUserId: row.custGlobalId,
                groupId: this.urlParams.groupId,
            }
            this.getChartList()
            this.chartModal = true
        },
        chartCurrentPageFn(page) {
            this.chartPageNum = page
            this.getChartList()
        },
        chartPageSizeFn(pageSize) {
            this.chartPageSize = pageSize
            this.getChartList()
        },
        getChartList() {
            let params = {
                groupId: this.chartData.groupId,
                chatUserId: this.chartData.chatUserId,
                page: this.chartPageNum,
                pageSize: this.chartPageSize,
            }
            this.$get("/sendmsg/pc/chat/record/selectRecordPage", params)
                .then(res => {
                    if (res.code === "200") {
                        res.dataList.map(item => {
                            item.status = item.status === 1 ? false : true //0公开 1隐藏
                        })
                        this.chartModalData = res.dataList
                        this.chartTotal = res.maxCount
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "数据获取失败",
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        changeStatus(data) {
            if (data.id) {
                let params = {
                    recordId: data.id,
                    status: data.status ? 0 : 1,
                }
                this.$post("/sendmsg/pc/chat/record/changeRecordStatus", params)
                    .then(res => {
                        if (res.code === "200") {
                            // console.log(res)
                            this.$Message.success("操作成功")
                        } else {
                            this.$Message.success("操作失败")
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        changeAddStatus(value) {
            this.addStatus = value
            if (!value) {
                this.userSelectList = []
                this.allSelectList = []
                this.getList()
            } else {
                this.$nextTick(() => {
                    // 刷新
                    this.$refs.selecttable.refresh()
                })
            }
        },
        selectList(val) {
            this.allSelectList = val
        },
        saveSetPeople() {
            let List = []
            if (this.allSelectList.length == 0) {
                this.$Message.warning({
                    content: "请选择成员",
                    background: true,
                })
                return
            } else {
                this.allSelectList.map(item => {
                    List.push(item.custGlobalId)
                })
            }
            let data = {
                custGlobalId: List,
                groupId: this.urlParams.groupId,
                communityCode: this.urlParams.orgCode,
            }
            this.addGridOperator(data)
        },
        addGridOperator(data) {
            this.addLoading = true
            this.$post("/sendmsg/pc/chat/groupCreate/addChatGroupUser", data, { "Content-Type": "application/json" }).then(res => {
                if (res.code == "200") {
                    this.addLoading = false
                    this.$Message.success({
                        content: "新增成功",
                        background: true,
                    })

                    this.changeAddStatus(false)
                    this.getList()
                } else {
                    this.addLoading = false
                    this.$Message.error({
                        content: "新增失败",
                        background: true,
                    })
                }
            })
        },

        /**
         * 打开个人聊天窗口
         */
        onOpenMessageDetail(item) {
            const loading = this.$Message.loading({
                content: "连接中...",
                duration: 0,
            })

            this.$get("/gateway/chat/app/chat/onlyUser/flushUser", {
                onlyUserId: item.custGlobalId,
            })
                .then(res => {
                    if (res.code !== "200" || !res.data) {
                        return this.$Message.error(res.desc || res.msg || "建立连接失败，请稍后再试！")
                    }

                    this.iframeUrl = `${proxy["/custom"].target}/#/pages/im/person/chat?groupCode=${res.data}&groupName=${item.nickname}&businessType=1&businessCode=2&custGlobalId=${item.custGlobalId}&token=${window.sessionStorage.getItem("accessToken")}`
                })
                .finally(() => {
                    loading()
                })
        },

        /**
         * 发公告
         */
        onMassNotice() {
            this.$core.openLayerFrame({
                type: 2,
                title: `群发公告`,
                content: `/page#/groupedit?sendType=1&name=${"群发公告"}`,
                area: ["100%", "100%"],
            })
        },

        //        //异步加载社区
        // loadingCommunityFn(query) {
        //    if(query !== '' && !Number(query)) {
        //     this.loadingCommunity = true;
        //    this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        //    if(this.nodeList.length > 50) this.nodeList.length = 50;
        //     this.loadingCommunity = false;
        //   } else if(Number(query)) {
        //     return;
        //   }
        //   else {
        //     this.nodeList = [];
        //   }
        // },
        // getSelect(){
        //    this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
        //       staffId: parent.vue.loginInfo.userinfo.id,
        //       orgCode:parent.vue.loginInfo.userinfo.orgCode
        //     })
        //       .then((res) => {
        //         if (res.code === "200"&&res.dataList&&res.dataList.length>0) {
        //           // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
        //           this.nodeList = res.dataList.map((item) => {
        //             return {
        //               value: item.code,
        //               label: item.fullName,
        //               projectCode: item.projectCode
        //             };
        //           });
        //           this.allNodeList = JSON.parse(JSON.stringify(this.nodeList));
        //           if(this.nodeList.length > 50) {
        //             this.nodeList.length = 50;
        //           }
        //         }
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //       });
        // },
        // getTree(val){
        //     this.nodeId = val
        //     this.uploadData.orgCode = this.nodeId
        // }
    },
    components: {
        LiefengTable,
        LiefengContent,
        LiefengModal,
        Selecttable,
        CompModal,
    },
}
</script>

<style scoped lang="less">
.group {
    /deep/ #modal_contentarea {
        overflow: hidden !important;
    }
}
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
    width: 30px;
    height: 30px;
}
</style>
